import React from "react";
import { useState, useEffect } from "react";
import { assemenent_test_list } from "../../../apis/My-e-Test/AssessmentTest";
import Pagination from "../../Pagination";
import jsPDF from "jspdf";
import { Link } from "react-router-dom";
import { generate_token } from "../../../apis/My-e-Test/HomeWork";
const Assessment = () => {
  const [itemsPerPage] = useState(5);
  const [testList, setTestList] = useState([]);
  const [currentPages, setCurrentPages] = useState(() => {
    if (testList?.length > 0) {
      return testList?.reduce((acc, ele) => {
        acc[ele.courseid] = 1;
        return acc;
      }, {});
    }
    return {};
  });


  const state = localStorage.getItem("state");
  const sid = localStorage.getItem("sid");
  const grade_name = localStorage.getItem("gradeName");

  useEffect(() => {
    async function get_data() {
      const testlist = await assemenent_test_list();
      setTestList(testlist?.data);
    }
    get_data();
  }, []);
  const onPageChange = (page, courseid, termid) => {
    const pageKey = `${courseid}-${termid}`;
    setCurrentPages((prevPages) => ({
      ...prevPages,
      [pageKey]: page,
    }));
  };


const onSubmit = async (data) => {
    const payload = {
      aid: data,
      state: state,
      sid: sid,
      grade: grade_name,
      sto: 120,
      type: "free_assessement"
    }
    const res = await generate_token(payload);
    if (res?.status) {
      window.open(res?.data, "_blank");
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="table_head">
          <h2 className="">Assessment</h2>
          <p>
            <b>* online tests</b> are strictly for students of{" "}
            <b>that grade.</b>
          </p>
        </div>
          {testList?.length > 0 &&
            testList?.map((ele) => {
              const currentPageKey = `${ele?.year}-${ele?.grade_name}-${ele?.term_name}`;
              const currentPage = currentPages[currentPageKey] || 1;
              const itemsPerPage = 5;
              const startIndex = (currentPage - 1) * itemsPerPage;
              const endIndex = startIndex + itemsPerPage;

              return (
                <div key={`${ele?.year}-${ele?.grade_name}-${ele?.term_name}`}>
                  <h5>
                    {ele?.year}  {`Year ${ele?.grade_name}`} - {ele?.term_name}
                  </h5>
                  <div className="table-responsive tbl_bg">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "40%" }}>
                            Title
                          </th>
                          <th scope="col" style={{ width: "40%" }}>
                            Test Period
                          </th>
                          {/* <th scope="col" style={{ width: "40%" }}>
                           Time
                          </th> */}
                          <th scope="col" style={{ width: "40%" }}>
                           Take Test
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ele?.assessments?.length > 0 ? (
                          ele?.assessments
                            ?.slice(startIndex, endIndex)
                            ?.map((assessment, idx) => {
                              const title = `${assessment?.titles?.year} ${assessment?.titles?.type} ${assessment?.titles?.subject_name}`;
                              return (
                                <tr
                                  key={`${assessment?.tstm_guid}-${idx}-${ele?.term_name}`}
                                >
                                  <td>{title}</td>
                                  <td>{assessment?.test_period}</td>
                                  {/* <td>{assessment?.test_total_time} min</td> */}
                                  {/* href={assessment?.tstm_guid} */}
                                  <td>
                                    <a
                                     
                                      onClick={() => { onSubmit(assessment?.tstm_guid) }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="take-test"
                                      style={{cursor:"pointer"}}
                                    >
                                      Take Test
                                    </a>
                                    
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="3">No Assessments Available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagin">
                    <Pagination
                      onPageChange={(page) =>
                        onPageChange(
                          page,
                          ele.year,
                          ele.grade_name,
                          ele.term_name
                        )
                      }
                      totalPages={Math.ceil(
                        ele.assessments.length / itemsPerPage
                      )}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              );
            })}
        <div className="tbl_content">
          <ol type="1">
            <li>
              * Assessment test must be completed within above  test period .
            </li>
            <li>
              * You can take the test ONLY ONCE during the available period.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Assessment;
