import React from "react";
import { useState, useEffect } from "react";
import { HomeWork_list, generate_token } from "../../../apis/My-e-Test/HomeWork";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Pagination from "../../Pagination";

const Homework = () => {
  const [HomeWorkList, setHomeWorkList] = useState([]);
  const [currentPages, setCurrentPages] = useState(() => {
    if (HomeWorkList?.homeworks?.length > 0) {
      return HomeWorkList.homeworks.reduce((acc, ele) => {
        acc[ele.courseid] = 1;
        return acc;
      }, {});
    }
    return {};
  });
  const state = localStorage.getItem("state");
  const sid = localStorage.getItem("sid");
  useEffect(() => {
    async function get_data() {
      const worklist = await HomeWork_list();
      setHomeWorkList(worklist?.data);
    }
    get_data();
  }, []);
  const onPageChange = (page, courseid, termid) => {
    const pageKey = `${courseid}-${termid}`;
    setCurrentPages((prevPages) => ({
      ...prevPages,
      [pageKey]: page,
    }));
  };

  const [itemsPerPage] = useState(12);

  //   let generatePDF = () => {
  //     const doc = new jsPDF();
  //     if (workList) {
  //       const tableData = workList.map((record) => {
  //         // Flatten the cardDetails array and combine it with other fields
  //         const flattenedRecord = {
  //           ...record,
  //         };
  //         return Object.values(flattenedRecord);
  //       });

  //       const tableHeaders = Object.keys(workList[0]);

  //       doc.autoTable({
  //         columnStyles: {
  //           6: { cellWidth: 25 },
  //           7: { cellWidth: 30 },
  //         },
  //         head: [tableHeaders],
  //         body: tableData,
  //       });

  //       // doc.save('Userslist.pdf');
  //       doc.output("dataurlnewwindow");
  //     }
  //   };
  const onSubmit = async (data) => {
    const payload = {
      aid: data,
      state: state,
      sid: sid,
      grade: HomeWorkList?.grade,
      sto: 120,
      type: "hd_homework"
    }
    const res = await generate_token(payload);
    if (res?.status) {
      window.open(res?.data, "_blank");
    }
  }
  return (
    <div className="row">
      <div className="col-12">
        <div className="table_head">
          <h2 className="">Homework List</h2>
        </div>
        {HomeWorkList?.homeworks?.length > 0 &&
          HomeWorkList?.homeworks?.map((ele) => {
            const currentPageKey = `${ele.courseid}-${ele.termid}`;
            const currentPage = currentPages[currentPageKey] || 1;
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;

            return (
              <div key={`${ele.courseid}-${ele.termid}`}>
                <h3>{ele?.course_name}</h3>
                <h5>
                  {ele?.year} {ele?.grade_names} - {ele?.term_name}
                </h5>
                <div className="table-responsive tbl_bg lectures">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "15%" }}>
                          Core Skills
                        </th>
                        {ele?.subjectdata?.length > 0 &&
                          ele?.subjectdata?.map((sub) => (
                            <th
                              scope="col"
                              style={{ width: "15%" }}
                              key={`${sub.subject_id}-${ele.termid}`}
                              className="text-capitalize"
                            >
                              {sub?.subject}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {ele?.units?.length > 0 ? (
                        ele?.units
                          ?.slice(startIndex, endIndex)
                          ?.map((unit, unitIndex) => (
                            <React.Fragment
                              key={`${unit?.unit_id}-${unitIndex}-${ele.termid}`}
                            >
                              <tr>
                                <td style={{ cursor: "pointer" }}>
                                  {unit?.name || "-"}
                                </td>
                                {ele?.subjectdata?.length > 0 &&
                                  ele?.subjectdata?.map((sub) => {
                                    const unitDataForSubject =
                                      unit.subjects?.find(
                                        (subject) =>
                                          subject.subject_id === sub.subject_id
                                      )?.units_json_data || [];
                                    return (
                                      <td
                                        key={`${unit.unit_id}-${sub.subject_id}-${unitIndex}-${ele.termid}`}
                                      >
                                        {unitDataForSubject?.length > 0
                                          ? unitDataForSubject
                                            ?.filter(
                                              (jsonData) =>
                                                !!jsonData?.abbreviation &&
                                                !!jsonData?.tstm_guid
                                            )
                                            ?.map((jsonData, idx) => {
                                              return (
                                                <React.Fragment
                                                  key={`${unit.unit_id}-${sub.subject_id}-${unitIndex}-${idx}-${ele.termid}`}
                                                >
                                                  <a
                                                    // href={jsonData?.tstm_guid}
                                                    // target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                      marginRight: 5,
                                                      color: "red",
                                                      cursor: 'pointer'
                                                    }}
                                                    onClick={() => { onSubmit(jsonData?.tstm_guid) }}
                                                  >
                                                    {jsonData?.abbreviation}
                                                  </a>
                                                  {idx <
                                                    unitDataForSubject?.length -
                                                    1 && " | "}
                                                </React.Fragment>
                                              )
                                            }
                                            )
                                          : "-"}
                                      </td>
                                    );
                                  })}
                              </tr>
                            </React.Fragment>
                          ))
                      ) : (
                        <tr>
                          <td colSpan="6">-</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="pagin">
                  <Pagination
                    onPageChange={(page) =>
                      onPageChange(page, ele.courseid, ele.termid)
                    }
                    totalPages={Math.ceil(ele.data?.length / itemsPerPage)}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            );
          })}
        <div className="tbl_content">
          <ol type="1">
            {/* <li>* If you are not enrolled in CS Online School, please <span className="join">Join Membership first</span> and then take the test.</li> */}
            <li>
              You can take the test ONLY ONCE during the available period.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Homework;
