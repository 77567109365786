import { HTTPURL } from "../../constants/Matcher";

export const HomeWork_list = async (currentPage) => {
    try {
        // let local = 'http://127.0.0.1:4040/'
        const response = await fetch(HTTPURL + `api/student/student-all-home-works`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('Token')}`
                }
            }
        );
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error?.message;
    }
}

export async function consulting(data) {
    console.log('data', data)
    data.user_id = localStorage.getItem('studentId')
    data.type = 'consulting'
    try {
        const response = await fetch(HTTPURL + 'api/student/add-consulting', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData?.status) {
            console.log('name', responseData?.data);
        }
        // else {
        //   responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function e_tutoring(data) {
    console.log('data', data)
    data.user_id = localStorage.getItem('studentId')
    data.type = 'e-tutoring'
    try {
        const response = await fetch(HTTPURL + 'api/student/add-consulting', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData?.status) {
            console.log('name', responseData?.data);
        }
        // else {
        //   responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}




export async function generate_token(data) {
    console.log('data', data)
    data.user_id = localStorage.getItem('studentId')
    //data.type = 'hd_homework'
    try {
        const response = await fetch(HTTPURL + 'api/generate-token', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData?.status) {
            console.log('name', responseData?.data);
        }
        // else {
        //   responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}